import styled from "styled-components";

export const FaqTableBox = styled.div`
    width: 100%;
    border-bottom: solid 1px #ddd;
`;

export const FaqTableWrapper = styled.div`
    width: 100%;
    transition: all 0.2s ease-in-out;
    border-top: ${(props) => props.borderTop};
    ${(props) =>
        props.mobile
            ? `
        border-top: ${props.open ? "solid 1px #1e1e1e;" : "solid 1px #ddd"};
    `
            : `
        border-top: ${props.open ? "solid 1px #1e1e1e;" : "solid 1px #ddd"};
    `}
    background-color: ${(props) => (props.open ? "#f8f8f8" : "#ffffff")};
`;

export const FaqTableRow = styled.div`
    width: 100%;
    height: auto;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    > * {
        border-bottom: unset !important;
    }
`;

export const FaqTableCell = styled.div`
    display: flex !important;
    align-items: flex-start !important;
    padding: 0px !important;
    border-bottom: none !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38 !important;
    letter-spacing: -0.48px !important;
    text-align: left !important;
    color: #000;
    margin: 20px;
    flex: ${(props) => props.flex};
`;

export const Content = styled.p`
    margin: 0;
    padding: 0;
    white-space: pre-line;
`;

export const FaqBox = styled.div`
    transition: all 0.2s ease-in-out;
    border-bottom: none;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.42px;
    text-align: left;
    color: #1e1e1e;

    width: 100%;
    overflow: hidden;

    ${(props) =>
        props.open
            ? `
        max-height: 500px;
        ${
            props.mobile
                ? `
            padding: 0 20px 0;
        `
                : `
            padding: 12px 20px 0;
        `
        }
    `
            : `
        padding: 0 20px;
        max-height: 0px;
    `}
`;

///////// notice

export const NoticeTableBox = styled.div`
    width: 100%;
    border-bottom: solid 1px #ddd;
`;

export const NoticeDivision = styled.div`
    ${(props) =>
        props.mobile
            ? `
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.36px;
        text-align: left;
        color: #999;
    `
            : `
        width: 64px !important;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        border: solid 2px #999;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: -0.3px;
        text-align: center;
        color: #707070;
        
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    `}
`;

export const NoticeTableRow = styled.div`
    width: 100%;
    border-top: solid 1px #ddd;
    display: flex;
    align-items: center;
    ${(props) =>
        props.mobile
            ? `
        flex-flow: row wrap;
        justify-content: flex-start;
        height: auto;
        padding: 16px 0;
        > div {
            &:nth-child(1) {
                width: auto;
                min-width: auto;
                border-right: solid 1px #999;
                padding: 0 8px 0 0;
            }
            &:nth-child(2) {
                width: auto;
                min-width: auto;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.67;
                letter-spacing: -0.12px;
                text-align: left;
                color: #999;
                padding: 0 0 0 8px;
            }
            &:nth-child(3) {
                padding: 0 20px;
            }
        }
    `
            : `
        ${
            props.head
                ? `
            width: 100%;
            height: 36px !important;
            > div {
                font-size: 13px !important;
            }        
        `
                : `
            height: 56px;
        `
        }
        justify-content: space-between;
    `}
`;

export const NoticeTableCell = styled.div`
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.39px;
    text-align: left;
    color: #1e1e1e !important;
    order: ${(props) => props.order};

    ${(props) =>
        props.division &&
        `
        min-width: 64px;
        padding-left: 20px !important;
    `};

    ${(props) =>
        props.text &&
        `
        width: 100%;
        padding-left: 72px;
        color: #1e1e1e !important;
    `};

    ${(props) =>
        props.date &&
        `
        min-width: 95px;
        padding-right: 20px !important;
        font-size: 14px;
        line-height: 1.54;
        letter-spacing: -0.13px;
        color: #707070 !important;
    `};
`;
