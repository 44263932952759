import React, { useMemo } from "react"
import styled from "styled-components"

const Text = styled.span`
    margin: 0;
    padding: 0;
    ${props => props.bold && `font-weight: bold`}
`

export const HighlightText = ({ bold, text, keyword }) => {
    if (text === undefined) return ""
    let parts = text.split(new RegExp(`(${keyword})`, "gi"))
    return (
        <>
            {parts.map((part, i) => (
                <Text key={i} bold={bold} style={keyword && part.toLowerCase() === keyword.toLowerCase() ? { color: "#713838", fontWeight: "bold" } : {}}>
                    {part}
                </Text>
            ))}
        </>
    )
}
