import styled from "styled-components";

export const TitleText = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    font-size: ${(props) => props.fontSize};
    text-align: left;
    color: #b5b5b5;
    cursor: pointer;
    padding: ${(props) => props.padding};

    ${(props) =>
        props.type === "download"
            ? `
        > div {
            ${
                props.mobile
                    ? `
                width: 24px;    
                height: 24px;
            `
                    : `
                width: 32px;
                height: 32px;
            `
            }
            margin: 0 0 2px 4px;
        }
    `
            : `
        > div {
            ${
                props.mobile
                    ? `
                width: 20px;    
                height: 20px;
            `
                    : `
                width: 24px;
                height: 24px;
            `
            }
            margin: 0 0 0 2px;
        }
    `};
`;

export const TitleIconWrapper = styled.div``;

export const Divider = styled.div`
    background-color: #2f2f2f;
    ${(props) =>
        props.mobile
            ? `
    width: 100%;
    height: 1px;
`
            : `
    width: 1px;
    height: 40px;
`}
`;
