import React, { useState, useEffect, useMemo } from "react";

import Seo from "components/seo";
import Top from "organisms/support/top";
import Tab from "organisms/support/tab";
import Notice from "organisms/support/notice/notice";
import { graphql } from "gatsby";
import { NOTICE_CATEGORY } from "../../../constants/data/support";
import { NOTICE_META, NOTICE_META_EN } from "../../../constants/data/meta_data";
import LayoutSupport from "../../../templates/layoutSupport";
import InquiryBanner from "../../../organisms/banner/inquiryBanner";
import axios from "axios";
import { useTranslation } from "react-i18next";

const NoticePage = ({ data }) => {
    const [_data, setData] = useState([]);
    useEffect(() => {
        axios.get(process.env.GATSBY_NOTICE_ENDPOINT).then((res) => {
            setData(res.data);
        });
    }, []);

    const { i18n } = useTranslation();

    const seoData = useMemo(() => {
        if (i18n.language === "en") return NOTICE_META_EN;

        return NOTICE_META;
    }, [i18n.language]);

    return (
        <LayoutSupport>
            <Seo title={seoData.title} description={seoData.description} canonical={seoData.canonical} kakaoKeyword={seoData.kakao} />
            <Top />
            <Tab />
            {/* <Notice data={data.notice_data.edges} category={NOTICE_CATEGORY}/> */}
            {_data.length > 0 && <Notice data={_data} category={NOTICE_CATEGORY} />}
            <InquiryBanner />
        </LayoutSupport>
    );
};

export default NoticePage;

export const NoticeData = graphql`
    query ($language: String!) {
        notice_data: allNoticeDataCsv(sort: { fields: date, order: DESC }) {
            edges {
                node {
                    id
                    category
                    title
                    desc
                    date
                }
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
