import React, { useEffect, useMemo, useState } from "react";
import ResponsiveMedia from "utils/responsive_media";
import { navigate } from "gatsby-link";
import { globalHistory } from "@reach/router";
import { TabContainer, TabBox, TabButton } from "./style";
import TextTab from "../parts/textTab";
import { SupportActions } from "redux/actions";
import { SUPPORT_TAB } from "constants/data/support";
import { useTranslation } from "react-i18next";

const Tab = () => {
    const { t, i18n } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    const location = globalHistory.location;
    const [active, setActive] = useState(null);

    const handleActiveTab = (index) => {
        if (active === index) return;

        SupportActions.setFaqCategory(0);
        SupportActions.setNoticeCategory(0);

        const path = i18n.language === "ko" ? SUPPORT_TAB.items[index].path : `/${i18n.language}${SUPPORT_TAB.items[index].path}`;

        navigate(path);
    };

    const handleSupportTab = () => {
        if (location.pathname.includes("faq")) {
            SupportActions.setSupportTab(0);
            setActive(0);
        } else if (location.pathname.includes("notice")) {
            SupportActions.setSupportTab(1);
            setActive(1);
        } else {
            navigate(SUPPORT_TAB.items[0].path);
        }
    };

    useEffect(() => {
        handleSupportTab();
    }, [location, isMobile]);

    return (
        <>
            {active !== null && (
                <>
                    {isMobile === "true" ? (
                        <>{i18n.language === "ko" ? <TextTab margin={"0 0 20px"} data={SUPPORT_TAB.items} onClickEvent={handleActiveTab} initIndex={active} /> : <></>}</>
                    ) : (
                        <>
                            {i18n.language === "ko" ? (
                                <TabContainer>
                                    <TabBox>
                                        {SUPPORT_TAB.items.map((item, index) => {
                                            return (
                                                <TabButton key={index} active={active === index} onClick={() => active !== index && handleActiveTab(index)}>
                                                    {t(item.title)}
                                                </TabButton>
                                            );
                                        })}
                                    </TabBox>
                                </TabContainer>
                            ) : (
                                <></>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default Tab;
