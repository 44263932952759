// export const SUPPORT_TAB = {
//     items: [
//         {
//             title: '자주 묻는 질문',
//             path: '/support/faq',
//         },
//         {
//             title: '공지사항',
//             path: '/support/notice',
//         }
//     ]
// }

// export const FAQ_CATEGORY = [
//     '전체',
//     '서비스 이용 관련',
//     '요금제 및 계정',
//     '서명/도장 관련',
//     '법적효력 및 인증',
//     '보안',
//     '기타',
// ]

// export const NOTICE_CATEGORY = [
//     '전체',
//     '공지',
//     '업데이트',
// ]

export const SUPPORT_TAB = {
    items: [
        {
            title: "SUPPORT_TAB_ITEMS_1_TITLE",
            path: "/support/faq"
        },
        {
            title: "SUPPORT_TAB_ITEMS_2_TITLE",
            path: "/support/notice"
        }
    ]
};

export const FAQ_CATEGORY = ["FAQ_CATEGORY_ALL", "FAQ_CATEGORY_SERVICE_USAGE", "FAQ_CATEGORY_SUBSCRIPTION_ACCOUNT", "FAQ_CATEGORY_SIGNING_STAMP", "FAQ_CATEGORY_LEGAL_VALIDITY_CERTIFICATION", "FAQ_CATEGORY_SECURITY", "FAQ_CATEGORY_OTHER"];

export const NOTICE_CATEGORY = ["NOTICE_CATEGORY_ALL", "NOTICE_CATEGORY_ANNOUNCEMENT", "NOTICE_CATEGORY_UPDATES"];
