import React from "react";
import { connect } from "react-redux";
import ResponsiveMedia from "utils/responsive_media";
import { GaEventTracter } from "../../../utils/gaTracker";

import { Box, Container } from "../../../components/style/layoutStyle";
import Image from "components/image/image";
import { TextNormal } from "../../../components/style/textStyle";
import { SERVICE_BANNER } from "../../../constants/data/public";
import { Divider, TitleIconWrapper, TitleText } from "./style";
import { useTranslation } from "react-i18next";

const SupportServiceBanner = ({ serviceGuide, serviceIntroduce }) => {
    const { isMobile } = ResponsiveMedia();
    const { t, i18n } = useTranslation();

    const handleOpenUrl = (url) => {
        if (url === "사용가이드") {
            GaEventTracter("서비스개선", "도뉴 사용 가이드");
            let guideUrl = serviceGuide;
            url = guideUrl;
        }
        if (url === "서비스소개서") {
            GaEventTracter("서비스개선", "도뉴 서비스 소개서");
            url = serviceIntroduce;
        }
        window.open(url, "_blank");
    };

    return (
        <Container height={isMobile === "true" ? "auto" : "80px"} flexDirection={isMobile === "true" ? "column" : "row"} bgColor={"#242424"}>
            {SERVICE_BANNER.map((item, index) => {
                return (
                    <>
                        <Box key={index} alignitems={"center"} justifycontent={"center"}>
                            <TitleText
                                mobile={isMobile === "true"}
                                type={item.link.type}
                                fontSize={isMobile === "true" ? "16px" : "18px"}
                                style={isMobile === "true" ? { height: "64px" } : { height: "100%" }}
                                onClick={() => handleOpenUrl(item.link.url)}
                            >
                                {t(item.title)}
                                <TitleIconWrapper>
                                    <Image filename={item.icon_support} alt={t(item.title)} />
                                </TitleIconWrapper>
                            </TitleText>
                        </Box>
                        {index === 0 && <Divider mobile={isMobile === "true"} />}
                    </>
                );
            })}
        </Container>
    );
};

export default connect(
    (state) => ({
        serviceGuide: state.publicData.service_guide_link,
        serviceIntroduce: state.publicData.service_introduce_link
    }),
    null
)(SupportServiceBanner);
