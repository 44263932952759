import React from "react";
import ResponsiveMedia from "utils/responsive_media";

import { SupportActions } from "redux/actions";
import { Container, Box, Wrapper } from "components/style/layoutStyle";
import { TextBold, TextNormal } from "components/style/textStyle";
import SearchInput from "../../components/input/searchInput";
import Image from "../../components/image/image";
import { IMG_SUPPORT_CUSTOMER } from "../../constants/media/images";
import styled from "styled-components";
import { navigate } from "gatsby-link";
import { useTranslation } from "react-i18next";

const ImageWrapper = styled.div`
    width: 360px;
    height: 270px;
`;

const Top = () => {
    const { t, i18n } = useTranslation();

    const { isMobile } = ResponsiveMedia();

    const searchKeyword = (keyword) => {
        SupportActions.setKeyword(keyword);

        const path = i18n.language === "ko" ? "/support/search" : `/${i18n.language}/support/search`;

        navigate(path);
    };

    return (
        <Container padding={"0 20px"} height={isMobile === "true" ? "80px" : "320px"} bgColor={"#fff"} margin={isMobile === "true" ? "0" : "0 0 50px"}>
            <Box>
                <Wrapper style={{ maxWidth: "592px" }}>
                    {!isMobile && (
                        <>
                            <TextBold type={"42"} textAlign={isMobile === "true" ? "center" : "left"}>
                                {t("SUPPORT_FAQ_TITLE")}
                            </TextBold>
                            <TextNormal type={"20"} textAlign={isMobile === "true" ? "center" : "left"} margin={"12px 0 4px"} color={"#1e1e1e"}>
                                {t("SUPPORT_FAQ_DESC")}
                            </TextNormal>
                        </>
                    )}
                    <SearchInput searchEvent={searchKeyword} />
                </Wrapper>
                {!isMobile && (
                    <ImageWrapper>
                        <Image filename={IMG_SUPPORT_CUSTOMER} alt="support img" />
                    </ImageWrapper>
                )}
            </Box>
        </Container>
    );
};

export default Top;
