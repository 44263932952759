import React, { useEffect, useState } from "react";
import { IC_PAGINATION_ARROW } from "../../constants/media/icon";
import Image from "../image/image";

import { Box } from '../style/layoutStyle';
import { PageArrowWrapper, PageNumber } from "./style";

const Pagination = ({data, onClickEvent}) => {

    const count = Math.ceil(data.length/10);
    const [active, setActive] = useState(1);

    const handlePage = (event) => {
        setActive(Number(event.target.innerText));
        onClickEvent && onClickEvent(Number(event.target.innerText));
    }

    const handlePageNextAndPrev = (page) => {
        setActive(Number(page));
        onClickEvent && onClickEvent(Number(page));
    }

    const organizePage = () => {
        const page = [];
        for(let i=1; i<=count; i++){
               page.push(<PageNumber key={i} active={active === i} onClick={handlePage}>{i}</PageNumber>);
        }
        return page;
    }

    useEffect(() => {
        setActive(1);
    }, [count])

    return (
        <Box
            justifycontent={'center'}
            gap={'12px'}>
                <PageArrowWrapper onClick={() => handlePageNextAndPrev(1)}>
                    <Image filename={IC_PAGINATION_ARROW} alt='page arrow'/>
                </PageArrowWrapper>
                {organizePage()}
                <PageArrowWrapper next onClick={() => handlePageNextAndPrev(count)} >
                    <Image filename={IC_PAGINATION_ARROW} alt='page arrow'/>
                </PageArrowWrapper>
        </Box>
    )
}

export default Pagination;