import * as React from "react";
import PropTypes from "prop-types";
import Header from "../organisms/header";
import Footer from "../organisms/footer";
import SupportServiceBanner from "../organisms/banner/supportServiceBanner";
import LocaleBase from "./LocaleBase";

const LayoutSupport = ({ children, bgColor }) => {
    return (
        <LocaleBase>
            <Header />
            <div style={{ margin: `0 auto` }}>
                <main>{children}</main>
            </div>
            <SupportServiceBanner />
            <Footer />
        </LocaleBase>
    );
};

LayoutSupport.propTypes = {
    children: PropTypes.node.isRequired
};

export default LayoutSupport;
