import styled from "styled-components";

export const TabContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 20px;
`;

export const TabBox = styled.div`
    width: 1216px;
    display: flex;
    justify-content: center;
`;

export const TabButton = styled.div`
    flex: 1;
    max-width: 608px;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: -0.6px;
    text-align: center;

    ${(props) =>
        props.active
            ? `
        font-weight:500;
        background-color: #fff;
        color: #713838;
        border-top: solid 2px #713838;
    `
            : `
        color: #999;
        background-color: #efefef;
    `}
`;

// mobile nav
export const MobileNavTitle = styled.div`
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.48px;
    text-align: left;
    color: #1e1e1e;
`;

export const PrevButtonWrapper = styled.div`
    width: 20px;
    height: 20px;
    object-fit: contain;
    cursor: pointer;
    transform: rotate(90deg);
    position: absolute;
    left: 20px;
`;
