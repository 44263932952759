import React from "react";
import ResponsiveMedia from "utils/responsive_media";
import { Container, Box } from "components/style/layoutStyle";
import { TextNormal } from "components/style/textStyle";
import Image from "components/image/image";
import { INQUIRY_BANNER } from "../../../constants/data/public";
import { IC_PHONE_BR } from "../../../constants/media/icon";
import { useTranslation } from "react-i18next";

const InquiryBanner = () => {
    const { t } = useTranslation();
    const { isMobile } = ResponsiveMedia();

    return (
        <Container padding={isMobile === "true" ? "0 20px 60px" : "0 20px 120px"}>
            <Box padding={isMobile === "true" ? "30px 60px" : "47px 70px 46px 80px"} flexDirection={isMobile === "true" ? "column" : "row"} bgColor={"#faf7ef"}>
                <TextNormal
                    fontFamily={"NotoSansKR-Medium"}
                    padding={isMobile === "true" ? "0 0 12px" : "0"}
                    type={isMobile === "true" ? "18" : "24"}
                    textAlign={isMobile === "true" ? "center" : "left"}
                    style={{ fontWeight: "500", wordBreak: "keep-all" }}
                >
                    {t(INQUIRY_BANNER.title)}
                </TextNormal>
                <Box width={"auto"} flexDirection={"column"} alignitems={isMobile === "true" ? "center" : "flex-end"}>
                    <Box width={"auto"}>
                        <Image filename={IC_PHONE_BR} alt={INQUIRY_BANNER.title} style={isMobile === "true" ? { width: "24px", height: "24px" } : { width: "32px", height: "32px" }} />
                        <TextNormal fontFamily={"Lato-Medium"} type={isMobile === "true" ? "20" : "28"} color={"#713838"} padding={isMobile === "true" ? "0 0 8px" : "0 0 3px"} style={{ fontWeight: "500", wordBreak: "keep-all" }}>
                            {t(INQUIRY_BANNER.phone)}
                        </TextNormal>
                    </Box>
                    <TextNormal fontFamily={"NotoSansKR-Medium"} padding={"0"} type={isMobile === "true" ? "13" : "16"} color={"#999"}>
                        {t(INQUIRY_BANNER.desc)}
                    </TextNormal>
                </Box>
            </Box>
        </Container>
    );
};

export default InquiryBanner;
