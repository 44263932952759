import React, { useState } from "react";

import { SearchbarContainer, SearchbarBox, SearchbarInput, IconWrapper, ErrorMessage } from "./style";
import Image from "../image/image";
import { IC_SEARCH, IC_X } from "../../constants/media/icon";
import { useTranslation } from "react-i18next";

const SearchInput = ({ searchEvent }) => {
    const { t } = useTranslation();

    const [keyword, setKeyword] = useState("");
    const [keywordError, setKeywordError] = useState(false);

    const handleSearchInput = (event) => {
        if (event.target.value.length >= 2 || event.target.value.length === 0) {
            setKeywordError(false);
        }
        setKeyword(event.target.value);
    };

    const searchKeyword = () => {
        if (keyword === "" || keyword.length < 2) {
            setKeywordError(true);
            return;
        }
        searchEvent && searchEvent(keyword);
    };

    const handleOnKeyPress = (event) => {
        if (event.key === "Enter") {
            searchKeyword();
        }
    };

    const removeKeyword = () => {
        setKeyword("");
    };

    return (
        <SearchbarContainer>
            <SearchbarBox style={keywordError ? { border: "solid 1px #cd001a" } : {}}>
                <SearchbarInput placeholder={t("SUPPORT_FAQ_PLACE_HOLDER")} onKeyPress={handleOnKeyPress} onChange={handleSearchInput} value={keyword} />
                {keyword && keyword.length > 0 && (
                    <IconWrapper width={"8px"} height={"8px"} margin={"0 5px"} onClick={() => removeKeyword()}>
                        <Image filename={IC_X} alt="search X" />
                    </IconWrapper>
                )}

                <IconWrapper onClick={() => searchKeyword()}>
                    <Image filename={IC_SEARCH} alt="search" />
                </IconWrapper>
            </SearchbarBox>
            <ErrorMessage style={keywordError ? { visibility: "visible" } : { visibility: "hidden" }}>{t("SUPPORT_FAQ_INPUT_ERROR_TEXT")}</ErrorMessage>
        </SearchbarContainer>
    );
};

export default SearchInput;
